
// import { parse } from 'marked'
// import {Rive }from "@rive-app/canvas";
import bodytext from "@/mixins/bodytext";
import HeroSVG from "@/components/svg/hero/HeroSVG.vue";
import ScrollSVG from "@/components/svg/hero/ScrollSVG.vue";
import MobileDetailSVG from "@/components/svg/hero/MobileDetailSVG.vue";
import BodyWrap from "@/components/wrappers/BodyWrap.vue";
// import LottieLogo from "@/components/parts/LottieLogo.vue";
import RiveLogo from "@/components/parts/RiveLogo.vue";
import scrambleText from "@/mixins/gsap-anims.js";

// import logorive from "@/assets/rive/logo.riv";

export default {
  components: {
    // LottieLogo,
    RiveLogo,
    HeroSVG,
    ScrollSVG,
    MobileDetailSVG,
    BodyWrap,
  },
  mixins: [bodytext, scrambleText],
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tl: null,
    };
  },
  // computed: {
  //   parsedBody() {
  //     return this.section.body ? parse(this.section.body) : ''
  //   },
  // },
  beforeMount() {
    this.$nuxt.$on("go", this.anim);
  },
  mounted() {

    // const r = new Rive({
    //     src: "/rive/logo.riv",
    //     canvas: this.$refs.logo,
    //     autoplay: true,
    //     stateMachines: "State Machine 1",
    //     onLoad: () => {
    //       r.resizeDrawingSurfaceToCanvas();
    //     },
    // });

    const faders = [];
    for (const child of this.$refs.heroSVG.$el.children) {
      if (!child.classList.contains("ring") && !child.classList.contains("dontfade"))
        faders.push(child);
    }

    this.tl = this.$gsap.timeline({
      defaults: { ease: "custom.1" },
      scrollTrigger: {
        trigger: this.$el,
      },
    });
    this.tl.scrollTrigger.disable();

    const ring = this.$refs.heroSVG.$el.querySelectorAll(".ring");
    const cog = this.$refs.heroSVG.$el.querySelectorAll(".bigcog");
    // const detail = this.$refs.heroSVG.$el.querySelectorAll('.flash')

    this.$screen.lg &&
      this.tl.from(
        cog,
        { rotate: 90, transformOrigin: "center", duration: 1, ease: "back.out(1.5)" },
        ">"
      );

    const splitWord = new this.$SplitText(this.$refs.heading, { type: "words, lines" });
    this.tl.from(
      splitWord.words,
      { opacity: 0, translateX: 64, duration: 0.1, stagger: 0.1, ease: "custom.1" },
      "<"
    );

    this.tl.addLabel("words");

    this.$screen.lg &&
      this.tl.from(
        ring,
        {
          scale: 1.1,
          rotate: 45,
          duration: 0.4,
          transformOrigin: "center",
          ease: "back.out(1.5)",
        },
        "<"
      );
    this.$screen.lg &&
      this.tl.from(faders, { opacity: 0, duration: 0.3, stagger: 0.01 }, "<");

    this.$refs.logo &&
      //this.tl.from(this.$refs.logo, { opacity: 0, duration: 0.3 }, "<");
      this.tl.from(this.$refs.logo.$el, { opacity: 0, duration: 0.3 }, "<");

    if (this.$refs.body) {
      this.tl.from(this.$refs.bodyWrap.$el, { opacity: 0, duration: 0.2 }, ">");
      this.tl.from(this.$refs.bodyWrap.$el, {
        scale: 0.2,
        transformOrigin: "center",
        duration: 0.3,
      });
      this.tl.add(this.scrambleText(this.$refs.body, 0.015));
    }

    const wtl = this.$gsap.timeline({ repeat: -1 });
    const len = 360 / 24;
    const words = this.$refs.words?.sort((a, b) => 0.5 - Math.random());
    words &&
      words.forEach((word, index) => {
        wtl.to(
          cog,
          { rotate: len * (index + 1), transformOrigin: "center", ease: "back.out(1.7)" },
          "<"
        );
        // wtl.to(detail, { opacity: 0.2, duration: 0.3 }, '>')
        const splitWord = new this.$SplitText(word, { type: "chars, words" });
        wtl.to(word, { opacity: 1, duration: 0.1 }, ">");
        wtl.from(
          splitWord.words,
          { translateX: 64, duration: 0.1, stagger: 0.05, ease: "back.out(1.5)" },
          "<"
        );
        wtl.to(
          splitWord.chars.reverse(),
          { translateX: 32, opacity: 0, duration: 0.1, stagger: 0.03, ease: "custom.1" },
          ">0.8"
        );
        wtl.set(word, { opacity: 0, duration: 0.1 }, ">");
        // wtl.to(detail, { opacity: 1, duration: 0.2 }, '<')
      });
    this.tl.add(wtl, "words");

    this.$emit("sectionMounted");
  },
  beforeDestroy() {
    this.$nuxt.$off("go", this.anim);
  },

  methods: {
    anim() {
      this.tl.scrollTrigger.enable();
    },
    progress() {
      this.$gsap.to(window, {
        duration: 1,
        scrollTo: this.$el.clientHeight,
        ease: "custom.1",
      });
    },
  },
};
