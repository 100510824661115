
import { Rive }from "@rive-app/canvas-lite";
export default {
  components: {
    // lottie,
  },
  props: {
    // logo: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  data() {
    return {
      anim: null,
      // anim: null,
      // lottieOptions: {
      //   animationData: this.logo?.lottie ? this.logo.lottie : logo.default,
      //   autoplay: false,
      //   loop: true,
      // },
    }
  },
  mounted() {
    const self = this

    this.anim = new Rive({
        src: "/rive/logo.riv",
        canvas: this.$refs.logo,
        autoplay: true,
        stateMachines: "State Machine 1",
        onLoad: () => {
          
          this.anim.resizeDrawingSurfaceToCanvas();
        },
    });

    this.$ScrollTrigger.create({
      trigger: this.$el,
      toggleActions: 'play reset play reverse',
      onEnter() {
        self.play()
      },
      onEnterBack() {
        self.play()
      },
      onLeave() {
        self.pause()
      },
      onLeaveBack() {
        self.pause()
      },
    })
  },
  beforeUnmount() {
    this.anim?.cleanup()
  },
  methods: {

    play() {
      this.anim.play()
    },

    pause() {
      this.anim.pause()
    },
  },
}
